import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCfs6p3wUUj9BtqAOToLhtBvEqZp2QicFs",
  authDomain: "pythonbasics-843a3.firebaseapp.com",
  projectId: "pythonbasics-843a3",
  storageBucket: "pythonbasics-843a3.appspot.com",
  messagingSenderId: "490926612804",
  appId: "1:490926612804:web:6f8a325213834c3972b711",
  measurementId: "G-VHB1G98GST"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
  
export default db;