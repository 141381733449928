import React, { useState, useEffect } from "react";
import db from "./components/Firebase";

function App() {

  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState(-1);

  useEffect(() => {
    getLessons();
  }, []);

  const getLessons = () => {
    db.collection("lessons").onSnapshot((querySnapshot) => {
      const saveLessons = [];
      querySnapshot.forEach((doc) => {
        saveLessons.push(doc.data());
      });
      saveLessons.sort((a, b) => a.lesson - b.lesson);
      setLessons(saveLessons);
    });

  }

  const handleChangeLesson = () => {
    window.scrollTo(0,0);
    if (lessons[lesson] !== undefined) {
      const l = lessons[lesson]; 
      return (
        <div>
          <div dangerouslySetInnerHTML={{__html: l.instructions}} />
          {((l.trinket !== undefined) && (l.trinket !== "")) ?
            <iframe src={l.trinket} width="100%" height="600" frameBorder="0" allowFullScreen></iframe>
            : <></>}
        </div>
      );
    } 
  }


  const welcome = (
  <>
      <img className="mx-auto d-block" src={'./pythonlogo.png'}></img>

      <p>Welcome to <code>pythonbasics.io</code>! In this website, you will learn the fundamentals of programming, and how to use the Python programming language. We understand that it can be very overwhelming to start
      learning how to program, so we broke it down into <em>byte</em>-sized lessons.</p>

      <p>The course is structured so that you will learn a fundamental practice to programming, then you will be shown an online Python interpreter, so that you don't need to install anything. You can run Python directly from your browser!</p>

      <p>Don't feel discouraged if you still don't understand some key concepts right away. Thinking like a computer doesn't exactly come natural to everyone, and takes time.</p>

      <p>Good luck, and happy programming!</p>
  </>
  );

  const thanks = (
    <>
    <p>Thanks for taking this course! We hope it helped you learn more about the fundamentals of programming with Python.</p>

    <p>If you found any issues with the course material, please feel free to <a href="mailto:edward.menser@gmail.com">email me here</a>.</p>
    </>
  );


  const handleChangePage = () => {
    if (lesson >= lessons.length) {
      return thanks;
    } else {
      return handleChangeLesson();
    }
  }

  return (
    <>


    <link rel="stylesheet" href="https://stackedit.io/style.css" />

      <div className="header-bar">
          <h1 className="mx-2"><code>pythonbasics.io</code> - Python & Programming Basics</h1>
      </div>

    <div className="app-container container">

      {(lesson === -1) ? welcome : handleChangePage()}

      
      <div>

      
      { ((lesson > 0) && (lesson < lessons.length)) ?
        <button type="button" className="btn btn-danger p-3 m-2 float-left" onClick={() => setLesson(lesson - 1)}>Previous Lesson</button>
        : <></>
      }

      {
       (lesson < lessons.length) ?
        <button type="button" className="btn btn-primary p-3 m-2 float-right" onClick={() => setLesson(lesson + 1)}>
          {(lesson === -1) ? "Begin" : "Next Lesson"}
        </button>
        : <></>
      }
      </div>
    </div>
    </>

  );
}

export default App;